import React from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import apiHelper from '../../utils/apiHelpers';
import './NewAside.css';

const NewAside = () => {
  const [user, setUser] = useState({ first_name: '', last_name: '' });
  const [collapsed, setCollapsed] = useState(true);
  const [background, setBackground] = useState(false);

  const onClickExpand = () => {
    if (!collapsed) {
      setCollapsed(true);
      setBackground(false);
    } else {
      setCollapsed(false);
      setBackground(true);
    }
  };

  const notBackground = () => {
    setBackground(false);
    setCollapsed(true);
  };

  return (
    <>
      {!collapsed ? (
        <div
          className="backAside fadeInA"
          onClick={notBackground}
          background={background}></div>
      ) : null}
      <nav className={`sidebar ${collapsed ? 'active' : ''}`}>
        <div className="sidebar-header">
          <img
            src={process.env.PUBLIC_URL + '/img/logo.png'}
            width="170px"
            alt="vcrypto"
          />
          <span className="pl-2 ">
            {user.first_name} {user.last_name}
          </span>
        </div>

        <ul className="list-unstyled components">
          {/*<li>
            <Link to={`${process.env.PUBLIC_URL}/`}>
              <i className="fas fa-table  mr-2 "></i>Tablero de Control
            </Link>
          </li>*/}

          <li>
            <Link
              to={`${process.env.PUBLIC_URL}/project`}
              onClick={e => onClickExpand()}>
              <i className="fas fa-clipboard mr-2 "></i> Proyectos
            </Link>
          </li>
          {apiHelper.isAdmin() && (
            <li>
              <Link
                to={`${process.env.PUBLIC_URL}/issues-delayed`}
                onClick={e => onClickExpand()}>
                <i class="fas fa-exclamation-circle"></i> Tareas demoradas
              </Link>
            </li>
          )}
          {(apiHelper.isAdmin() || apiHelper.isDEV() || apiHelper.isQA()) && (
            <>
              <li>
                <Link
                  to={`${process.env.PUBLIC_URL}/issues-pending`}
                  onClick={e => onClickExpand()}>
                  <i class="fas fa-tasks mr-2"></i> Tareas pendientes
                </Link>
              </li>
              <li>
                <Link
                  to={`${process.env.PUBLIC_URL}/milestones`}
                  onClick={e => onClickExpand()}>
                  <i className="fas fa-calendar-check  mr-2 "></i> Hitos
                </Link>
              </li>
              <li>
                <Link
                  to={`${process.env.PUBLIC_URL}/firebaseRemoteConfig`}
                  onClick={e => onClickExpand()}>
                  <i className="fas fa-cogs  mr-2 "></i> Configuraciones remotas
                </Link>
              </li>
            </>
          )}
          {apiHelper.isAdmin() && (
            <li>
              <Link
                to={`${process.env.PUBLIC_URL}/user`}
                onClick={e => onClickExpand()}>
                <i className="fas fa-user  mr-2 "></i> Usuarios
              </Link>
            </li>
          )}
          <hr />
          <li>
            <Link
              onClick={async e => {
                await apiHelper.resetOnboarding();
                onClickExpand();
              }}>
              <i className="fas fa-question  mr-2 "></i> Mostrar instructivos
            </Link>
          </li>
          <li>
            <Link to="/login" onClick={apiHelper.logout}>
              <i className="fas fa-sign-out-alt  mr-2 "></i> Cerrar sesión
            </Link>
          </li>
        </ul>
      </nav>
      <button
        type="button"
        collapsed={collapsed.toString()}
        className={`btn asideButton   ${collapsed ? 'activeButton' : ''}`}
        onClick={onClickExpand}>
        <i
          className={`${
            collapsed ? 'fas fa-align-center' : ' fas fa-arrow-circle-right '
          }`}></i>
      </button>
    </>
  );
};

export default NewAside;
