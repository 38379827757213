const axios = require('axios');
const config = require('../config/config');
const qs = require('qs');

/* APIs ENDPOINTS */
const SESSION_STORAGE_SESSION = 'session';
const SERVER_URL = config.backend.apiUrl;
const LOGIN_URL = SERVER_URL + '/auth/login';
const CHANGE_PASSWORD_URL = SERVER_URL + '/auth/change-password';
const RESET_URL = SERVER_URL + '/auth/reset';
const GENERATE_PASSWORD_URL = SERVER_URL + '/auth/generate-password';
const PROJECTS_URL = SERVER_URL + '/project';
const MILESTONES_URL = SERVER_URL + '/milestone';
const ISSUES_URL = SERVER_URL + '/issue';
const COMMENTS_URL = SERVER_URL + '/comment';
const FRC_URL = SERVER_URL + '/firebaseRemoteConfig';
const USERS_URL = SERVER_URL + '/user';
const USER_ROLES_URL = SERVER_URL + '/userRole';

function getBackendUrl() {
  return SERVER_URL;
}

// Auth APIs

function login(payload) {
  return axios.post(LOGIN_URL, payload).then(function (response) {
    const token = response.data.token;
    localStorage.setItem(
      SESSION_STORAGE_SESSION,
      JSON.stringify({ token: response.data.token }),
    );
    getCurrentUser().then(function (userResponse) {
      let userData = userResponse.data;
      userData.token = token;
      localStorage.setItem(SESSION_STORAGE_SESSION, JSON.stringify(userData));
      return userData;
    });
  });
}

function resetPassword(payload) {
  return axios
    .post(RESET_URL, {
      username: payload.email,
    })
    .then(function (response) {
      return response;
    });
}

function changePassword(payload) {
  return axios
    .post(
      CHANGE_PASSWORD_URL,
      {
        username: payload.username,
        password: payload.password,
        newPassword: payload.newPassword,
      },
      buildHeaders(),
    )
    .then(function (response) {
      return response;
    });
}

function generatePassword(payload) {
  return axios
    .post(GENERATE_PASSWORD_URL, payload, buildHeaders())
    .then(function (response) {
      return response;
    });
}

function logout() {
  localStorage.clear(SESSION_STORAGE_SESSION);
}

function isAuthenticated() {
  return localStorage.getItem(SESSION_STORAGE_SESSION) !== null;
}

function isAdmin() {
  return (
    localStorage.getItem(SESSION_STORAGE_SESSION) !== null &&
    JSON.parse(localStorage.getItem(SESSION_STORAGE_SESSION)).userRole &&
    JSON.parse(localStorage.getItem(SESSION_STORAGE_SESSION)).userRole
      .userRole == 'ADMIN'
  );
}

function isClient() {
  return (
    localStorage.getItem(SESSION_STORAGE_SESSION) !== null &&
    JSON.parse(localStorage.getItem(SESSION_STORAGE_SESSION)).userRole &&
    JSON.parse(localStorage.getItem(SESSION_STORAGE_SESSION)).userRole
      .userRole == 'CLIENT'
  );
}

function isDEV() {
  return (
    localStorage.getItem(SESSION_STORAGE_SESSION) !== null &&
    JSON.parse(localStorage.getItem(SESSION_STORAGE_SESSION)).userRole &&
    JSON.parse(localStorage.getItem(SESSION_STORAGE_SESSION)).userRole
      .userRole == 'DEV'
  );
}

function isQA() {
  return (
    localStorage.getItem(SESSION_STORAGE_SESSION) !== null &&
    JSON.parse(localStorage.getItem(SESSION_STORAGE_SESSION)).userRole &&
    JSON.parse(localStorage.getItem(SESSION_STORAGE_SESSION)).userRole
      .userRole == 'QA'
  );
}

function getUserId() {
  if (localStorage.getItem(SESSION_STORAGE_SESSION) == null) return;
  return JSON.parse(localStorage.getItem(SESSION_STORAGE_SESSION)).id;
}

function getOnboarding(onboardingName) {
  if (localStorage.getItem(SESSION_STORAGE_SESSION) == null) return false;
  if (
    JSON.parse(localStorage.getItem(SESSION_STORAGE_SESSION))[onboardingName] ==
      undefined ||
    JSON.parse(localStorage.getItem(SESSION_STORAGE_SESSION))[onboardingName] ==
      null
  )
    return false;
  return JSON.parse(localStorage.getItem(SESSION_STORAGE_SESSION))[
    onboardingName
  ];
}

function updateLocalOnboarding(onboardingName, state) {
  if (localStorage.getItem(SESSION_STORAGE_SESSION) == null) return false;
  if (
    JSON.parse(localStorage.getItem(SESSION_STORAGE_SESSION))[onboardingName] ==
      undefined ||
    JSON.parse(localStorage.getItem(SESSION_STORAGE_SESSION))[onboardingName] ==
      null
  )
    return false;
  let newState = JSON.parse(localStorage.getItem(SESSION_STORAGE_SESSION));
  newState[onboardingName] = state;
  localStorage.setItem(SESSION_STORAGE_SESSION, JSON.stringify(newState));
}

function buildHeaders() {
  if (localStorage.getItem(SESSION_STORAGE_SESSION)) {
    return {
      headers: {
        Authorization:
          'Bearer ' +
          JSON.parse(localStorage.getItem(SESSION_STORAGE_SESSION)).token,
      },
    };
  } else {
    return null;
  }
}

// Projects APIs

function getProjects(parameters = []) {
  return axios.get(
    `${PROJECTS_URL}?${qs.stringify(parameters, { skipNulls: true })}`,
    buildHeaders(),
  );
}

function getProjectsCount(parameters = []) {
  return axios.get(
    `${PROJECTS_URL + '/count'}?${qs.stringify(parameters, {
      skipNulls: true,
    })}`,
    buildHeaders(),
  );
}

function updateRepositoryStructure(project) {
  return axios.post(PROJECTS_URL + '/' + project, {}, buildHeaders());
}

function createPullRequest(project, issues, validate) {
  return axios.post(
    PROJECTS_URL + '/' + project + '/pull',
    { issues: issues, validate: validate },
    buildHeaders(),
  );
}

// Milestones APIs

function getMilestones(parameters = []) {
  return axios.get(
    `${MILESTONES_URL}?${qs.stringify(parameters, { skipNulls: true })}`,
    buildHeaders(),
  );
}

function getMilestonesCount(parameters = []) {
  return axios.get(
    `${MILESTONES_URL + '/count'}?${qs.stringify(parameters, {
      skipNulls: true,
    })}`,
    buildHeaders(),
  );
}

function createMilestone(project, title, description, dueDate) {
  return axios.post(
    MILESTONES_URL + '/' + project,
    {
      title: title,
      description: description,
      dueDate: dueDate,
    },
    buildHeaders(),
  );
}

// Issues APIs

function getIssues(project, parameters = []) {
  return axios.get(
    `${ISSUES_URL + '/' + project}?${qs.stringify(parameters, {
      skipNulls: true,
    })}`,
    buildHeaders(),
  );
}

function getPendingIssues(project) {
  return axios.get(`${ISSUES_URL}/${project}/pending`, buildHeaders());
}

function getDelayedIssues(project) {
  return axios.get(`${ISSUES_URL}/${project}/delayed`, buildHeaders());
}

function getIssuesCount(project, parameters = []) {
  return axios.get(
    `${ISSUES_URL + '/' + project + '/count'}?${qs.stringify(parameters, {
      skipNulls: true,
    })}`,
    buildHeaders(),
  );
}

function getIssue(project, id) {
  return axios.get(ISSUES_URL + '/' + project + '/' + id, buildHeaders());
}

function postIssue(project, body) {
  return axios.post(ISSUES_URL + '/' + project + '/', body, buildHeaders());
}

function patchIssue(project, id, body) {
  return axios.patch(
    ISSUES_URL + '/' + project + '/' + id,
    body,
    buildHeaders(),
  );
}

function moveIssue(id, body) {
  return axios.patch(ISSUES_URL + '/' + id, body, buildHeaders());
}

function postAttachment(project, id, payload) {
  const data = new FormData();
  data.append('file', payload);
  return axios.post(
    ISSUES_URL + '/' + project + '/' + id,
    data,
    buildHeaders(),
  );
}

function getIssuesReport(project, parameters = []) {
  return axios.get(
    `${ISSUES_URL + '/report/' + project}?${qs.stringify(parameters, {
      skipNulls: true,
    })}`,
    buildHeaders(),
  );
}

// Comments APIs

function getComments(project, id) {
  return axios.get(COMMENTS_URL + '/' + project + '/' + id, buildHeaders());
}

function getCommentsCount(parameters = []) {
  return axios.get(
    `${COMMENTS_URL + '/count'}?${qs.stringify(parameters, {
      skipNulls: true,
    })}`,
    buildHeaders(),
  );
}

function getComment(id) {
  return axios.get(COMMENTS_URL + '/' + id, buildHeaders());
}

function postComment(project, id, body) {
  return axios.post(
    COMMENTS_URL + '/' + project + '/' + id,
    body,
    buildHeaders(),
  );
}

function patchComment(id, body) {
  return axios.patch(COMMENTS_URL + '/' + id, body, buildHeaders());
}

// User APIs

function getUsers(parameters = []) {
  return axios.get(
    `${USERS_URL}?${qs.stringify(parameters, { skipNulls: true })}`,
    buildHeaders(),
  );
}

function getUsersCount(parameters = []) {
  return axios.get(
    `${USERS_URL + '/count'}?${qs.stringify(parameters, { skipNulls: true })}`,
    buildHeaders(),
  );
}

function getUser(id) {
  return axios.get(USERS_URL + '/' + id, buildHeaders());
}

function postUser(body) {
  return axios.post(USERS_URL + '/', body, buildHeaders());
}

function patchUser(id, body) {
  return axios.patch(USERS_URL + '/' + id, body, buildHeaders());
}

function deleteUser(id) {
  return axios.delete(USERS_URL + '/' + id, buildHeaders());
}

function getCurrentUser() {
  return axios.get(USERS_URL + '/me', buildHeaders());
}

function getUserRoles(parameters = []) {
  return axios.get(
    `${USER_ROLES_URL}?${qs.stringify(parameters, { skipNulls: true })}`,
    buildHeaders(),
  );
}

function sendNewUserEmail(id) {
  return axios.get(USERS_URL + '/' + id + '/email', buildHeaders());
}

function updateOnboarding(body) {
  updateLocalOnboarding(body.onboarding, body.state);
  return axios.patch(
    USERS_URL + '/onboarding/' + getUserId(),
    body,
    buildHeaders(),
  );
}

async function resetOnboarding() {
  await updateOnboarding({
    onboarding: 'onboardingIssueDetail',
    state: true,
  });
  await updateOnboarding({
    onboarding: 'onboardingProjectDetail',
    state: true,
  });
  await updateOnboarding({
    onboarding: 'onboardingProjects',
    state: true,
  });
}

function getFirebaseRemoteConfig() {
  return axios.get(FRC_URL, buildHeaders());
}

function getOneFirebaseRemoteConfig(name) {
  return axios.get(FRC_URL + '/' + name, buildHeaders());
}

function createFirebaseRemoteConfig(body) {
  return axios.post(FRC_URL, body, buildHeaders());
}

function updateFirebaseRemoteConfig(name, body) {
  return axios.patch(FRC_URL + '/' + name, body, buildHeaders());
}

function deleteFirebaseRemoteConfig(name) {
  return axios.delete(FRC_URL + '/' + name, buildHeaders());
}

export default {
  SERVER_URL,
  getBackendUrl,
  logout,
  login,
  resetPassword,
  changePassword,
  generatePassword,
  isAuthenticated,
  isAdmin,
  isClient,
  isDEV,
  isQA,
  getOnboarding,
  getProjects,
  getProjectsCount,
  updateRepositoryStructure,
  createPullRequest,
  getMilestones,
  getMilestonesCount,
  createMilestone,
  getIssues,
  getPendingIssues,
  getDelayedIssues,
  getIssuesCount,
  getIssue,
  postIssue,
  patchIssue,
  moveIssue,
  postAttachment,
  getIssuesReport,
  getComments,
  getCommentsCount,
  getComment,
  postComment,
  patchComment,
  getUsers,
  getUsersCount,
  getUser,
  postUser,
  patchUser,
  deleteUser,
  getCurrentUser,
  getUserRoles,
  sendNewUserEmail,
  updateOnboarding,
  resetOnboarding,
  getFirebaseRemoteConfig,
  getOneFirebaseRemoteConfig,
  createFirebaseRemoteConfig,
  updateFirebaseRemoteConfig,
  deleteFirebaseRemoteConfig,
};
