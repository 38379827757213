import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Link } from 'react-router-dom';
import { styled } from '../../../stitches.config';
import './Item.css';
import apiHelper from '../../../utils/apiHelpers';
import formatter from '../../../utils/formatter';

const Item = props => {
  const StyledItem = styled('div', {
    backgroundColor: '#eee',
    borderRadius: 4,
    padding: '4px 8px',
    transition: 'background-color .8s ease-out',
    marginTop: 8,

    ':hover': {
      backgroundColor: '#fff',
      transition: 'background-color .1s ease-in',
    },
  });

  const getTagClass = tag => {
    switch (tag) {
      case 'customer':
      case 'Customer':
        if (apiHelper.isClient()) {
          return '';
        } else {
          return <label className="tag tag-customer">Cliente</label>;
        }
        break;
      case 'Feature':
        return <label className="tag tag-feature">Funcionalidad</label>;
        break;
      case 'Error':
        return <label className="tag tag-error">Error</label>;
        break;
      case 'Support':
        return <label className="tag tag-support">Soporte</label>;
        break;
      case 'Development':
        return <label className="tag tag-development">Desarrollo</label>;
        break;
      case 'Production':
        return <label className="tag tag-production">Producción</label>;
        break;
      case 'Urgent':
        return <label className="tag tag-urgent">Urgente</label>;
        break;
      case 'Attention':
        return <label className="tag tag-attention">Requiere Atención</label>;
        break;
      case 'Suggestion':
        return <label className="tag tag-suggestion">Sugerencia</label>;
        break;
      default:
        return <label className="tag tag-default">{tag}</label>;
        break;
    }
  };

  const isDraggable = () => {
    return (
      apiHelper.isClient() &&
      (props.column == 'En Curso' ||
        props.column == 'QA' ||
        (props.column == 'Hechos' && props.issue.state == 'closed'))
    );
  };

  return (
    <Draggable
      draggableId={props.issue.id.toString()}
      index={props.index}
      isDragDisabled={isDraggable()}>
      {provided => (
        <StyledItem
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}>
          {
            <div>
              {props.issue.labels.map(label => {
                return getTagClass(label.name);
              })}
              <Link
                to={`${process.env.PUBLIC_URL}/project/${props.project}/issue/${props.issue.number}`}>
                <li className="list-group-item text-gray-800 clickable">
                  <span className="issueTitle">
                    <b>#{props.issue.number}</b> {props.issue.title}
                  </span>
                </li>
              </Link>
              <div className="d-flex flex-column">
                {props.issue.milestone ? (
                  <span className="issueTitle">
                    <i className="fas fa-sign mr-1" />
                    {props.issue.milestone.title}
                  </span>
                ) : (
                  ''
                )}
                {props.issue.state === 'closed' && !props.issue.locked ? (
                  <span className="issueTitle ml-1">
                    <i className="fas fa-calendar-check mr-1" />
                    {formatter.formatDateShort(props.issue.closed_at)}
                  </span>
                ) : (
                  ''
                )}
                {props.issue.state === 'closed' && props.issue.locked ? (
                  <span className="issueTitle ml-1">
                    <i className="fas fa-check mr-1" />
                    Desplegado
                  </span>
                ) : (
                  ''
                )}
                {props.issue.assignees.length > 0 ? (
                  <span className="issueTitle ml-1">
                    <i class="fas fa-solid fa-user mr-1"></i>
                    {props.issue.assignees.find(
                      assignee => assignee.role == 'DEV',
                    )
                      ? props.issue.assignees.find(
                          assignee => assignee.role == 'DEV',
                        ).login
                      : props.issue.assignees[0].login}
                  </span>
                ) : (
                  ''
                )}
              </div>
            </div>
          }
        </StyledItem>
      )}
    </Draggable>
  );
};

export default Item;
